import React from "react";

class Contact extends React.Component {
  render() {
    return (
      <div className="contact-container">
        <form
          method="post"
          action="../../backend/src/contact.php"
          id="mainForm"
        >
          <div className="form-style-3 contact">
            <label className="form-style-3">Nom :</label>
            <input className="form-style-3" type="text" name="nom" placeholder="Votre nom" minLength="3"required />
            <label className="form-style-3">Prénom :</label>
            <input className="form-style-3" type="text" name="prenom" placeholder="Votre prénom" minLength="3" required />
            <label className="form-style-3">Entreprise :</label>
            <input className="form-style-3" type="text" name="entreprise" placeholder="Votre entreprise"  minLength="" required />
            <label className="form-style-3">Vous êtes :</label>
            <select name="type">
              <option disabled>Merci de choisir une option ci-dessous :</option>
              <option value="un developpeur web">Une agence web</option>
              <option value="un formateur">Une entreprise privé</option>
              <option value="une entreprise privé">
                Une entreprise public
              </option>
              <option value="une entreprise public">
                Un organisme de formation
              </option>
            </select>
            <label className="form-style-3">Vous recherchez :</label>
            <select name="search">
              <option disabled>Merci de choisir une option ci-dessous :</option>
              <option value="un developpeur web">Un developpeur web</option>
              <option value="un formateur">Un formateur</option>
              <option value="un formateur">Un consultant</option>
            </select>
            <label className="form-style-3">Votre email :</label>
            <input className="form-style-3" type="email" name="email" placeholder="Votre email" required />
            <label className="form-style-3">Votre téléphone :</label>
            <input className="form-style-3" type="text" name="tel" placeholder="Votre telephone"  required />
        
          <label className="form-style-3">Message</label>
          <textarea name="message" refs="message" maxLength="300" minLength="20" required />
          <input type="submit" value="Envoyer" />  
          </div>
        </form>
      </div>
    );
  }
}

export default Contact;
