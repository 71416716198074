import React from 'react';
import Head from '../Components/Head';
import Menu from '../Components/Menu';

const NotFound = () => {
    return (
        <div>
            <Head title="PAGE NON TROUVÉE" />
            <Menu />
            <h1>Not Found</h1>
        </div>
    );
};

export default NotFound;