import React, { useState, useEffect } from "react";
import Menu from "../Components/Menu";
import Head from "../Components/Head";
import Title from "../Components/Title";
import Footer from "../Components/Footer";
import { avis } from "../lng/fr.js";

const Avs = () => {
  const [ratingValue, setNote] = useState(0);
  const [nom, setName] = useState("");
  const [comment, setComment] = useState("");
  const [entr, setEntr] = useState("");
  const [data,setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    //fetch("http://localhost/louisallard/backend/src/opinionsViewer.php")
   fetch("http://www.louisallard.fr/backend/src/opinionsViewer.php")
      .then(response => {
        if (response.ok === true) return response.json();
        else throw new Error(`Erreur HTTP => ${response.status}`);
      })
      .then(data => setData(data))
      .catch(err => setError(err.message));
  }, []);
  
  if (error) {
    return <div>Une erreur est survenue : {error}</div>;
  }
  
  if (data === null) {
    return <div>Chargement en cours...</div>;
  }
  

  return (
    <div className="apropos">
      <Head title="Votre avis" />
      <Menu />
      <Title text="Vos avis" />
      <div className="opinions-container">
        <div className="sent-comment">
        <h1 className="comm-title">{avis.titre2}</h1>
        {data.map((data => <div className="comments" key="{data}">
            <span>Nom : </span> {data.NP} ({data.COMPAGNY}) <br />
            <span>Note : </span><div className="star_small">{data.NOTE}</div> <br />
            <span>Commentaire : </span>{data.COMM} <br />
            </div>))}
        </div>

        <div className="send-comment">
        <h1 className="comm-title">{avis.titre} &#x1F609;</h1>
        <form action="http://www.louisallard.fr/backend/src/opinions.php" method="post">
          <div>
            {[...Array(5)].map((star, index) => {
              const ratingValue = index + 1;
              return (
                <label key={index}>
                  <input
                    type="radio"
                    name="rating"
                    value={ratingValue}
                    onClick={() => setNote(ratingValue)}
                    required
                  />
                  <span className="star"></span>
                </label>
              );
            })}
            <input
              type="text"
              placeholder="Entre tom nom et ton prénom ici"
              name="nom"
              maxLength={50}
              value={nom}
              onChange={(event) => setName(event.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Entre l'entreprise ou l'école dans laquelle on s'est connu ici"
              name="entr"
              maxLength={50}
              value={entr}
              onChange={(event) => setEntr(event.target.value)}
              required
            />
            <textarea
              maxLength={500}
              value={comment}
              name="comment"
              onChange={(event) => setComment(event.target.value)}
            ></textarea>
          </div>
          <button type="submit">Envoyer</button>
        </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Avs;
