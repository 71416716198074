import React from "react";
import Menu from "../Components/Menu";
import Head from "../Components/Head";
import Title from "../Components/Title";
import react from "../assets/about/react.png";
import sass from "../assets/about/sass.png";
import php from "../assets/about/php.png";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";
import { apropos } from "../lng/fr.js";

const Apropos = () => {
  return (
    <div className="apropos">
      <Head title="Me contacter" />
      <Menu />
      <Title text="Me contacter" />
      <div className="data-container"></div>
      <Contact/>
      <br />
      <br />
      <p> {apropos.txt}</p>

      <ul>
        <li>
          ReactJS : <img src={react} alt="ReactJS" />
          <li>
          PHP : <img src={php} alt="PHP" />
        </li>
        </li>
        <li>
          SCSS : <img src={sass} alt="SCSS" />
        </li>
      </ul>
      <Footer />
    </div>
  );
};

export default Apropos;
