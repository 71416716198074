import './App.css';
import { useEffect } from 'react';
import { Switch, Route, HashRouter, useLocation } from 'react-router-dom';
import { useAnalytics } from "use-analytics";
import { createBrowserHistory } from 'history';
import Home from './Pages/Home';
import About from './Pages/About';
import Projects from './Pages/Projects';
import Blog from './Pages/Blog';
import Cv from './Pages/Cv';
import Apropos from './Pages/Apropos';
import Podcasts from './Pages/Podcasts';
import Avis from './Pages/Avis';
import NotFound from './Pages/NotFound';

const history = createBrowserHistory();

 function App() {
   const location = useLocation();
  const analytics = useAnalytics();
  useEffect(() => {
    analytics.page()
  }, [location]);  // eslint-disable-line react-hooks/exhaustive-deps
 
  return (
    <div className="App">
         <HashRouter history={history}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" exact component={About} />
          <Route path="/projects" exact component={Projects} />
          <Route path="/blog" exact component={Blog} />
          <Route path="/podcasts" exact component={Podcasts} />
          <Route path="/cv" exact component={Cv} />
          <Route path="/avis" exact component={Avis} />
          <Route path="/apropos" exact component={Apropos} />
          <Route component={NotFound} />
        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
