// analytics.js
import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

const myPlugin = {
  name: 'my-custom-plugin',
  page: ({ payload }) => {
    return payload
    //console.log('page view fired', payload)
  },
  track: ({ payload }) => {
    return payload
   // console.log('track event payload', payload)
  }
}

const analyticsInstance = Analytics({
  app: 'louisallard',
  plugins: [
    myPlugin,
    googleAnalytics({
      measurementIds: ['UA-263753894-1']
    })
  ]
})

export default analyticsInstance